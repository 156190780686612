@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.login-container {
  width: 100%;
  overflow: auto;
  background: url('../images/login_img_bg.png');
  background-size: cover;
  background-position: center 0;
  background-repeat: no-repeat;
  position: absolute;
  height: 100vh;
  .login-tip {
    display: flex;
    width: fit-content;
    position: absolute;
    left: 50%;
    top: 27px;
    transform: translateX(-50%);
    width: 1110px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #252525;
    line-height: 22px;
  }
  .login-content {
    display: flex;
    // width: fit-content;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 1110px;
    justify-content: space-between;
    .login-left {
      .logo {
        width: 180px;
        img {
          width: 100%;
          height: 100%;
          display: inline-block;
        }
      }
      .login-left-title {
        font-size: 45px;
        font-family: AlibabaPuHuiTiB;
        color: #252525;
        line-height: 63px;
        font-weight: bold;
        margin: 55px 0 63px;
      }
      .login-left-list {
        .login-left-list-item {
          display: flex;
          align-items: center;
          img {
            width: 16px;
            height: 16px;
            display: inline-block;
            margin-right: 13px;
          }
          div {
            font-size: 19px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 27px;
          }
          & + .login-left-list-item {
            margin-top: 25px;
          }
        }
      }
    }
    .login-right {
      width: 416px;
      height: fit-content;
      background: #ffffff;
      box-shadow: 4px 7px 13px 3px rgba(0, 0, 0, 0.1);
      border-radius: 16px;
      margin-top: -66px;
      .login-info {
        padding: 36px 39px 23px;
        width: 100%;
        .login-card-wrapper {
          width: fit-content;
          margin: 0 auto;
          height: 320px;
          .login-card-title-wrapper {
            display: flex;
            align-items: center;
            width: fit-content;
            margin: 0 auto;
            img {
              width: 30px;
              margin-right: 9px;
            }
            div {
              font-size: 23px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #333333;
              line-height: 33px;
            }
          }
        }
        .wx-login-card-wrapper {
          .login-card-tip {
            margin: 9px 0 28px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
          }
          .login-qrcode {
            margin: 0 auto 23px;
            width: fit-content;
          }
        }
        .phone-login-card-wrapper {
          .login-card-title-wrapper {
            img {
              width: 20px;
            }
          }
          .phone-login-card {
            margin-top: 31px;
            ::placeholder {
              color: #999999;
              font-size: 14px;
            }
            .card-item {
              & + .card-item {
                margin-top: 22px;
              }
              .item-input-wrapper {
                margin: 14px 0 0;
                display: flex;
                .phone-input {
                  height: 47px;
                }
                .sms-input {
                  width: 205px;
                  height: 47px;
                  margin-right: 16px;
                  border-radius: 3px;
                  border: 1px solid #dbdbdb;
                  padding: 13px;
                  &:hover {
                    border-color: #ff5186;
                  }
                }
                .sms-btn {
                  border-radius: 3px;
                  border: 1px solid #e74d81;
                  background: #fff8fb;
                  width: 117px;
                  height: 47px;
                  color: #e74d81;
                  font-size: 14px;
                  font-family: PingFangSC-Regular, PingFang SC;
                  font-weight: 400;
                  // transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
                  transition: none;
                  &:hover {
                    color: #ff5186;
                    border-color: #ff5186;
                    background: #ffe1ec;
                  }
                  .ant-btn:hover,
                  .ant-btn:focus {
                    color: #ff5186;
                    border-color: #ff5186;
                    background: #ffe1ec;
                    box-shadow: none;
                  }
                }
                .ant-btn {
                  box-shadow: none;
                }
                button[ant-click-animating-without-extra-node]:after {
                  border: 0 none;
                  opacity: 0;
                  animation: none 0 ease 0 1 normal;
                }
                .ant-btn:active {
                  color: #fff;
                  border-color: #f55186;
                  background: #ff729c;
                }
                .ant-input-affix-wrapper:not(
                    .ant-input-affix-wrapper-disabled
                  ):hover {
                  border-color: #f55186;
                  border-right-width: 1px !important;
                  z-index: 1;
                }
                .ant-input-affix-wrapper:hover {
                  border-color: #f55186;
                  border-right-width: 1px !important;
                }
                .ant-input-affix-wrapper {
                  position: relative;
                  display: inline-block;
                  // width: 100%;
                  min-width: 0;
                  padding: 13px 13px;
                  color: rgba(0, 0, 0, 0.85);
                  font-size: 14px;
                  line-height: 1.5715;
                  background-color: #fff;
                  background-image: none;
                  border: 1px solid #dbdbdb;
                  // border-radius: 6px;
                  // transition: all 0.3s;
                  border-radius: 3px;
                  transition: none;
                  display: inline-flex;
                }
                .ant-input-affix-wrapper-focused {
                  border-color: #ff5186;
                  box-shadow: none;
                }
                .ant-input:focus,
                .ant-input-focused {
                  border-color: #ff5186;
                  box-shadow: none;
                }
                .ant-input-prefix {
                  margin-right: 13px;
                  .phone-input-prefix {
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    line-height: 20px;
                    border-right: 1px solid #dbdbdb;
                    padding-right: 13px;
                  }
                }
              }
            }
          }
          .login-btn-wrapper {
            background: #e74d81;
            border-radius: 3px;
            border: 1px solid #ff5186;
            width: 100%;
            height: 47px;
            font-weight: 500;
            color: #ffffff;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            margin: 33px auto 16px;
            line-height: 20px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              border-color: #ff729c;
              background: #ff729c;
            }
          }
          .phone-login-tip {
            margin-bottom: 27px;
            text-align: center;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
          }
        }
        .login-method-tip-wrapper {
          display: flex;
          margin-bottom: 18px;
          .login-method-tip-line {
            height: 1px;
            background: #eeeeee;
            margin: 9px 0;
            flex: 1;
          }
          .login-method-tip-text {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
            margin: 0 16px;
            flex: 1;
          }
        }
        .login-method-wrapper {
          width: fit-content;
          margin: 0 auto;
          img {
            width: 41px;
            height: 41px;
            display: block;
            margin: 0 auto 9px;
            cursor: pointer;
          }
          div {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #aaaaaa;
            line-height: 20px;
            cursor: pointer;
          }
        }
      }
      .login-info-bottom {
        background: #f9f9f9;
        border-radius: 0px 0px 16px 16px;
        padding: 16px 0;
        text-align: center;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(153, 153, 153, 1);
        line-height: 20px;
        span {
          color: rgba(102, 102, 102, 1);
          cursor: pointer;
        }
      }
    }
  }
}
