@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.user-edit-popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  .user-edit-popup-bg {
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .user-edit-popup-main {
    background: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    width: 500px;
    height: fit-content;
    max-height: 80%;
    .user-edit-popup-top {
      display: flex;
      align-items: center;
      padding: 20px 30px;
      border-bottom: 1px solid #e3e2e5;
      .popup-top-title {
        display: flex;
        margin: 0 auto;
        font-size: 20px;
        font-family: PingFang SC;
        font-weight: 800;
        line-height: 28px;
      }
      .popup-delete-icon {
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: flex-end;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .user-edit-popup-content {
    height: calc(100% - 160px);
    padding: 30px 30px 10px;
    min-height: 150px;
    .edit-item {
      .item-name {
        font-size: 13px;
        line-height: 16px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #000000;
      }
      .item-input-wrapper {
        margin: 10px 0 0;
        .item-icon {
          width: 15px;
          height: 15px;
          display: inline-block;
        }
        .item-input {
          width: 100%;
          height: 38px;
          border-radius: 5px;
        }
        .ant-input:focus,
        .ant-input-focused {
          border-color: #ff5186;
          box-shadow: none;
        }

        .ant-input:hover {
          border-color: #ff5186;
        }
      }
      .card-check-wrapper {
        margin-top: 17px;
        .card-check {
          & + .card-check {
            margin-top: 16px;
          }
          display: flex;
          align-items: flex-end;
          .checkbox {
            height: 16px;
            width: 16px;
            height: 16px;
            margin-right: 11px;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }
          .checkbox-disabled {
            cursor: not-allowed;
          }
          .check-text {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 12px;
            color: #000000;
          }
        }
      }
      .phone-card-wrapper {
        .item-input-wrapper {
          margin: 10px 0 0;
          .item-icon {
            width: 15px;
            height: 15px;
            display: inline-block;
          }
          .item-input {
            width: 100%;
            height: 38px;
            border-radius: 5px;
          }
          .ant-btn {
            box-shadow: none;
          }
          button[ant-click-animating-without-extra-node]:after {
            border: 0 none;
            opacity: 0;
            animation: none 0 ease 0 1 normal;
          }
          .ant-btn:active {
            color: #fff;
            border-color: #f55186;
            background: #ff729c;
          }
          // .ant-btn:hover,
          // .ant-btn:focus {
          //   color: #40a9ff;
          //   border-color: #40a9ff;
          //   background: #fff;
          // }
          .ant-input-affix-wrapper:not(
              .ant-input-affix-wrapper-disabled
            ):hover {
            border-color: #f55186;
            border-right-width: 1px !important;
            z-index: 1;
          }
          .ant-input-affix-wrapper:hover {
            border-color: #f55186;
            border-right-width: 1px !important;
          }
          .ant-input-affix-wrapper {
            position: relative;
            display: inline-block;
            // width: 100%;
            min-width: 0;
            padding: 4px 11px;
            color: rgba(0, 0, 0, 0.85);
            font-size: 14px;
            line-height: 1.5715;
            background-color: #fff;
            background-image: none;
            border: 1px solid #e3e2e5;
            // border-radius: 6px;
            // transition: all 0.3s;
            transition: none;
            display: inline-flex;
          }
          .ant-input-affix-wrapper-disabled {
            color: rgba(0, 0, 0, 0.25);
            background-color: #f5f5f5;
            border-color: #d9d9d9;
            box-shadow: none;
            cursor: not-allowed;
            opacity: 1;
            &:hover {
              border-color: #d9d9d9;
            }
          }
          .ant-input-affix-wrapper-focused {
            border-color: #ff5186;
            box-shadow: none;
          }
          // 手机验证码
          .sms-input {
            width: calc(100% - 100px);
            height: 38px;
            border-radius: 5px 0 0 5px;
          }
          .sms-btn {
            background: #f55186;
            border-color: #ff5186;
            width: 100px;
            height: 38px;
            color: #fff;
            font-size: 14px;
            line-height: 14px;
            // transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            transition: none;
            border-radius: 0 5px 5px 0;
            &:hover {
              border-color: none;
              border-color: #ff729c;
              background: #ff729c;
            }
            .ant-btn:hover,
            .ant-btn:focus {
              color: #fff;
              border-color: #ff729c;
              background: #ff729c;
            }
          }
        }
        .sms-card-item {
          margin-top: 10px;
        }
      }
    }
  }
  .tag-btn {
    padding: 23px 0 24px;
    display: flex;
    justify-content: center;
    div:nth-child(1) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #f8f8f8;
      border-radius: 5px;
      border: 1px solid #e3e2e5;
      width: 120px;
      height: 44px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #9797a1;
      font-size: 16px;
      margin: 0 30px 0 0;
      &:hover {
        background: #fff8fb;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
      &:active {
        background: #ffe1ec;
        color: #ff5186;
        border: 1px solid #ff5186;
      }
    }
    div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: #fff1f6;
      border-radius: 5px;
      border: 1px solid #ff5186;
      width: 120px;
      height: 44px;
      font-weight: 400;
      font-family: PingFang SC;
      color: #ff5186;
      font-size: 16px;
      &:hover {
        background: #fff8fb;
        border: 1px solid rgba(255, 81, 134, 0.6);
      }
      &:active {
        background: #ffe1ec;
        border: 1px solid #ff5186;
      }
    }
  }
}
