@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.edit-question-title {
  margin: 0;
  border: 1px dashed transparent;
  line-height: 22px; //16
  width: fit-content;
  max-width: 350px;
  padding: 4px 0; //8
  &:focus {
    border: 1px dashed #e3e2e5;
    box-shadow: none;
  }
  &:hover {
    border: 1px dashed #e3e2e5;
    box-shadow: none;
    min-width: 40px;
  }
  &:focus-visible {
    outline: none !important;
  }
  p {
    // background-color: antiquewhite;
    /* 去除点击p标签后的原生黄色边框 */
    outline: none;
  }
  p:empty::before {
    content: attr(placeholder);
    // color: #ccc;
    // font-size: 16px;
  }
}
