@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.question-info-list-current {
  border: 1px solid #ff5186 !important;
  .setting-icon {
    display: block !important;
    min-width: 120px;
  }
}
.question-info-list {
  border: 1px solid #e3e2e5;
  // width: 596px;
  width: calc(100% - 48px);
  min-width: 596px;
  background: #fff;
  border-radius: 6px 0 6px 6px;
  padding: 16px;
  box-sizing: border-box;
  position: relative;
  margin: 0 0 23px;
  .setting_edit_icon {
    position: absolute;
    top: -2px;
    right: -24px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  //题目标题行
  .info-list-top {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    // 问题题目
    .info-list-title {
      font-family: PingFang SC;
      font-weight: bold;
      color: #322d2c;
      font-size: 16px;
      display: flex;
      line-height: 32px;
      align-items: baseline;
      .info-list-title-sign {
        font-size: 12px;
        margin: 0 2px 0 0;
        width: 12px;
      }
      .isTransparent {
        color: transparent;
      }
      .isRed {
        color: #f5222d;
      }
      .info-list-title-type {
        margin: 0 0 0 16px;
      }
      .edit-question-title-wrap {
        margin: 0 15px 0 0;
        position: relative;
        &:hover {
          .edit-question-btn {
            display: block;
          }
        }
        .edit-question-btn {
          display: none;
          cursor: pointer;
          font-size: 12px;
          color: #595961;
          line-height: 20px;
          position: absolute;
          top: -16px;
          width: 100%;
          text-align: center;
          min-width: 68px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: normal;
          &:hover {
            color: #ff5186;
          }
        }
      }
      .upload-question-pic {
        .upload-pic {
          margin-bottom: 7px;
        }
      }
    }
    //问题题目右边的删除
    .setting-icon {
      display: none;
      .setting-icon-move {
        display: inline-block;
        margin: 0 0 0 24px;
        cursor: pointer;
      }
      .setting-icon-not-move {
        cursor: not-allowed;
      }
      .setting-icon-delete {
        display: inline-block;
        margin: 0 0 0 24px;
        cursor: pointer;
        .icon-delete {
          display: inline-block;
        }
        .icon-delete-active {
          display: none;
        }
        &:hover {
          .icon-delete {
            display: none;
          }
          .icon-delete-active {
            display: inline-block;
          }
        }
      }
      .setting-icon-copy {
        display: inline-block;
        margin: 0 0 0 24px;
        cursor: pointer;
        .icon-copy {
          display: inline-block;
        }
        .icon-copy-active {
          display: none;
        }
        &:hover {
          .icon-copy {
            display: none;
          }
          .icon-copy-active {
            display: inline-block;
          }
        }
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  //   修改text_area
  .ant-input:placeholder-shown {
    text-overflow: ellipsis;
  }
  textarea.ant-input {
    max-width: 100%;
    height: auto;
    min-height: 32px;
    vertical-align: bottom;
    // transition: all 0.3s, height 0s;
    transition: none;
  }
  .ant-input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
    position: relative;
    display: inline-block;
    width: 400px;
    min-width: 0;
    padding: 2px 10px !important;
    // height: 32px !important;
    font-size: 16px !important;
    // line-height: 32px;
    background-color: #fff;
    background-image: none;
    // border: 1px solid transparent;
    border: 1px dashed #e3e2e5;
    border-radius: 2px;
    // transition: all 0.3s;
    transition: none;
    font-family: PingFang SC !important;
    font-weight: bold !important;
    color: #322d2c;
    &:focus {
      border: 1px dashed #e3e2e5;
      box-shadow: none;
    }
    &:hover {
      border: 1px dashed #e3e2e5;
      box-shadow: none;
    }
  }
}
// 题目拖拽先显示区域
.drag_contain {
  width: 596px;
  min-width: 596px;
  height: 18px;
  border: 1px dotted #555;
  margin: 8px 0;
}
.isVisibility {
  visibility: hidden;
  height: 26px;
  margin: 0 0 8px;
  border: 1px dotted #555;
}
//固定三题
.drag_contain_for_fixed {
  height: 26px;
  margin: 0 0 8px;
  // border: 1px dotted #555;
}

//问题内容
.info-list-content {
  margin: 10px 0 0;
}
@media screen and (min-width: 1440px) {
  .question-info-list {
    // width: 596px;
    // width: calc(100% - 64px);
  }
}
