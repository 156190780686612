@function vw($args) {
  @return $args/1440 * 100 + vw;
}

.sidebar-container {
  position: fixed;
  z-index: 100;
  background: #fff;
  .sidebar-top-container {
    display: flex;
    .logo-wrapper {
      width: 188px;
      height: 55px;
      box-shadow: 0 10px 20px 0 #f8f9fd;
      position: relative;
      .logo-text-wrapper {
        text-align: center;
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .logo-img-2 {
          width: 85px;
          margin: 0 auto;
          img {
            width: 100%;
            height: 100%;
            display: inline-block;
            cursor: pointer;
          }
        }
        .logo-text {
          cursor: pointer;
          font-size: 12px;
          line-height: 12px;
          font-family: PingFang SC;
          color: #595961;
          margin-top: 8px;
        }
      }
    }
    .header-wrapper {
      position: fixed;
      background: #ffffff;
      height: 55px;
      top: 0;
      width: calc(100% - 188px);
      left: 188px;
      display: flex;
      justify-content: space-between;
      box-shadow: 1px 5px 10px 0px #f8f9fd;
      .header-left-wrapper {
        width: 60px;
        height: 50px;
        background: linear-gradient(90deg, #f8f9fd 0%, #ffffff 100%);
      }
      .header-right-wrapper {
        display: flex;
        .info-wrapper {
          display: flex;
          .info-item-wrapper {
            display: flex;
            align-items: center;
            margin-left: 18px;
            .info-item-img {
              width: 30px;
              height: 30px;
              img {
                width: 100%;
                height: 100%;
                display: inline-block;
              }
            }
            .info-item-text {
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 400;
              line-height: 30px;
              color: #000000;
              margin-left: 5px;
              .info-item-name {
                color: #808386;
              }
            }
          }
        }
        .avatar-wrapper {
          display: flex;
          align-items: center;
          margin: 0 50px 0 23px;
          .avatar-img {
            width: 30px;
            height: 30px;
            background: rgba(0, 0, 0, 0);
            border: 1px solid #ffffff;
            box-shadow: 0px 2px 4px #e7e9ef;
            border-radius: 50%;
            opacity: 1;
            img {
              width: 100%;
              height: 100%;
              display: inline-block;
              object-fit: cover;
            }
          }
          .avatar-text {
            font-size: 13px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 30px;
            color: #000000;
            margin-left: 8px;
          }
        }
      }
    }
  }
  .sidebar-left-container {
    // position: relative;
    left: 0;
    top: 0;
    background: #fff;
    width: 188px;
    height: calc(100vh - 55px);
    display: flex;
    overflow: hidden;
    padding-right: 6px;
    &:hover {
      overflow: auto;
      padding-right: 0;
    }
    .sidebar-content-wrapper {
      padding: 20px 24px 0;
      transition: all 0.4s ease;
      .sidebar-card {
        .sidebar-card-title-wrapper {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: bold;
          line-height: 14px;
          color: #302e2f;
          .sidebar-card-title {
            color: #302e2f;
          }
        }
        .sidebar-card-content {
          margin-top: 10px;

          .link-content-wrapper {
            display: flex;
            align-items: baseline;
            padding-left: 18px;
            cursor: pointer;
            width: fit-content;
            .link-content-img {
              width: 14px;
              height: 14px;
              margin-right: 8px;
              img {
                width: 100%;
                height: 100%;
                display: inline-block;
              }
            }
            .link-content-name {
              font-size: 13px;
              font-family: PingFang SC;
              font-weight: 400;
              line-height: 40px;
              color: #595961;
            }
            &:hover {
              .link-content-name {
                color: #f55186;
              }
            }
          }
        }
        &:first-child {
          .sidebar-card-title-wrapper {
            .sidebar-card-title {
              cursor: pointer;
              color: #302e2f;
              transition: none;
              &:hover {
                color: #f55186;
              }
            }
          }
        }
        & + .sidebar-card {
          margin: 38px 0 0 0;
        }
        &:nth-child(3) {
          .link-content-wrapper:nth-child(3) {
            align-items: unset;
            .link-content-img {
              width: 23px;
              height: 18px;
              margin-left: -5px;
              display: inline-block;
              margin-top: 7px;
            }
          }
        }
        &:nth-child(4) {
          .link-content-wrapper:nth-child(4) {
            align-items: unset;
            .link-content-img {
              width: 23px;
              height: 18px;
              margin-left: -5px;
              display: inline-block;
              margin-top: 7px;
            }
          }
        }
      }
    }
    .active {
      color: #f55186 !important;
      .sidebar-card-title {
        color: #f55186 !important;
      }
      .link-content-name {
        color: #f55186 !important;
      }
    }
  }
}
